@font-face {
    font-family: "Bon";
    src: url("./fonts/Bon.otf") format("opentype");
}

@font-face {
    font-family: "BonRegular";
    src: url("./fonts/BonRegular.otf") format("opentype");
}

@font-face {
    font-family: "LucidaGrande";
    src: url("./fonts/LucidaGrande-Light.otf") format("opentype");
}

body {
    height: 100%;
    margin: 0;
    box-sizing: border-box;
    overscroll-behavior: contain;
}

#root {
    height: 100%;
}

canvas {
    height: 100%;
    width: 100%;
}
